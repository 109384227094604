import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import { HIGHLIGHT_CLASSNAME } from "../../constants"

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: "table-row",
      [`&.${HIGHLIGHT_CLASSNAME}`]: {
        backgroundColor: "rgb(53, 59, 69)",
        display: "block",
        margin: theme.spacing(0, -2),
        padding: theme.spacing(0, 1, 0, 0.5),
        borderLeft: `${theme.spacing(0.5)}px solid #f99`,
      },
    },
  }
})

export default function Line(props) {
  const { children, className } = props
  const classes = useStyles()

  return <div className={clsx(classes.root, className)}>{children}</div>
}
