import React from "react"
import PropTypes from "prop-types"

import { Box, Button } from "@material-ui/core"

export default function LoadComments(props) {
  const { onClick } = props

  return (
    <Box textAlign="center">
      <Button onClick={onClick}>Load Comments</Button>
    </Box>
  )
}

LoadComments.propTypes = {
  onClick: PropTypes.func.isRequired,
}
