import React from "react"

import { useLocation } from "@reach/router"
import queryString from "query-string"

import { withStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"

import { Container, SEO, CircularProgress } from "@src/components"
import {
  postCommentUnsubscribe,
  isSuccessResponse,
  parseResponse,
  cancelRequest,
} from "@views/post/components/Comments/components/ShowComments/utils/api.js"
import { deleteSubscribe } from "@components/Layout/components/Footer/components/Subscribe/utils"
import { validateQueries } from "@views/shared/utils"

const META_TAGS = [
  { name: "robots", content: "noindex,nofollow" },
  { name: "googlebot", content: "noindex,nofollow" },
]
const QUERIES = ["email", "type"]

const StyledContainer = withStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
})(Container)

export default function UnSubscribe() {
  const [content, setContent] = React.useState("Unsubscribe")
  const { search } = useLocation()

  const handleError = React.useCallback(function handleError(error) {
    console.error(error)
    setContent("Something went wrong.")
  }, [])

  React.useEffect(
    function unsubsribeOnMounted() {
      let promise
      const queries = queryString.parse(search)
      const queriesAreValid = validateQueries(QUERIES, queries)

      if (!queriesAreValid) {
        setContent("Invalid email and type.")
      } else {
        try {
          setContent("fetching")

          switch (queries.type) {
            case "comments":
              promise = postCommentUnsubscribe({
                email: queries.email,
                type: queries.type,
              })
              break

            case "newsletters":
              promise = deleteSubscribe(queries.email)
              break

            default:
              throw new Error("Invalid unsubcsribe type.")
          }

          promise
            .then(isSuccessResponse)
            .then(parseResponse)
            .then(setContent)
            .catch(handleError)
        } catch (error) {
          handleError(error)
        }
      }

      return function onUnMounted() {
        cancelRequest(promise, "Component un mounted.")
      }
    },

    // skip static method cancelRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  )

  return (
    <>
      <SEO title="Unsubscribe" meta={META_TAGS} />
      <StyledContainer>
        <Typography variant="body1">
          {content === "fetching" ? <CircularProgress /> : content}
        </Typography>
      </StyledContainer>
    </>
  )
}
