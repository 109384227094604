const keyword = "// show-line-number"
const keywordLength = keyword.length

export default function useShowLineNumber(content) {
  let showLineNumber = false

  if (content.replace(/\s/g, "").includes(keyword.replace(/\s/g, ""))) {
    // Remove the line from the original
    content = content.substring(keywordLength + 1)
    showLineNumber = true
  }
  return { content, showLineNumber }
}
