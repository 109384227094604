// https://github.com/FormidableLabs/prism-react-renderer/issues/26#issuecomment-629013121
import { HIGHLIGHT_CLASSNAME } from "../constants"

let highlightStart = false

export default function highlightLine(lineArray, lineProps) {
  let shouldExclude = false

  lineArray.forEach((line, i) => {
    const content = line.content

    // Highlight lines with "// highlight-line"
    if (content.replace(/\s/g, "").includes("//highlight-line")) {
      lineProps.className = `${lineProps.className} ${HIGHLIGHT_CLASSNAME}`
      line.content = content
        .replace("// highlight-line", "")
        .replace("//highlight-line", "")
    }

    // Stop highlighting
    if (highlightStart && content.replace(/\s/g, "") === "//highlight-end") {
      highlightStart = false
      shouldExclude = true
    }

    // Start highlighting after "//highlight-start"
    if (content.replace(/\s/g, "") === "//highlight-start") {
      highlightStart = true
      shouldExclude = true
    }
  })

  // Highlight lines between //highlight-start & //highlight-end
  if (highlightStart) {
    lineProps.className = `${lineProps.className} ${HIGHLIGHT_CLASSNAME}`
  }

  return shouldExclude
}
