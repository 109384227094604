import React from "react"
import PropTypes from "prop-types"

import { withStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"
import ReactMarkdown from "react-markdown"

const StyledGrid = withStyles(theme => {
  return {
    root: {
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        alignItems: "center",
        textAlign: "left",
      },
    },
  }
})(Grid)

export default function Intro(props) {
  const { content } = props

  return (
    <StyledGrid item xs={12} sm={8}>
      <Typography variant="h5" component="h1" gutterBottom>
        <ReactMarkdown source={content} />
      </Typography>
    </StyledGrid>
  )
}

Intro.propTypes = {
  content: PropTypes.string.isRequired,
}
