import React from "react"

import { Image } from "@src/components"

export default function MarkdownImage(images) {
  return props => {
    const { src } = props
    const imgProps = images.find(img => img.url === src)?.props

    return <Image {...imgProps} />
  }
}
