import React from "react"

import { makeStyles, withStyles } from "@material-ui/core/styles"
import { Box } from "@material-ui/core"
import clsx from "clsx"

const StyledPreWrapper = withStyles(theme => ({
  root: {
    backgroundColor: "rgba(0,0,0,0.8)",
    borderRadius: "1rem",
    color: theme.palette.primary.light,
    overflow: "auto",
    "& pre[class*='language-']": {
      backgroundColor: "transparent",
      float: "left",
      minWidth: "100%",
    },
  },
}))(Box)

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: "left",
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
    overflow: "auto",
    // "& .token-line": {
    //   lineHeight: "1.3em",
    //   height: "1.3em",
    // },
  },
}))

export default function Pre(props) {
  const { children, className } = props
  const classes = useStyles()

  return (
    <StyledPreWrapper boxShadow={3}>
      <pre className={clsx(classes.root, className)}>{children}</pre>
    </StyledPreWrapper>
  )
}
