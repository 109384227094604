import React from "react"

export default function HeadingRenderer(props) {
  const children = React.Children.toArray(props.children)
  const text = children.reduce(flatten, "")
  const slug = text.toLowerCase().replace(/\W/g, "-")

  return React.createElement("h" + props.level, { id: slug }, props.children)
}

function flatten(text, child) {
  return typeof child === "string"
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text)
}
