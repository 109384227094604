import { API } from "aws-amplify"

export { cancelRequest, parseResponse, isSuccessResponse } from "@utils"

export function getComments(articleId) {
  return API.get("commentsApi", `/comments?article=${articleId}`)
}

export function postComment(body) {
  return API.post("commentsApi", "/comments", { body })
}

export function postCommentUnsubscribe(body) {
  return API.post("commentsApi", "/comments/unsubscribe", { body })
}
