import React from "react"

import { Blockquote, Container, Divider, SEO } from "@components"

import imageFactory from "@services/imageFactory"
import { Posts, Author } from "./components"

export default function Home(props) {
  const {
    allStrapiArticle: { edges },
    strapiAuthor: { avatar, bio },
  } = props.data

  return (
    <>
      <SEO title="Home" />
      <Container>
        <Author avatar={imageFactory.createFromDB(avatar)} bio={bio} />
        <Divider />
        <Blockquote>Blog</Blockquote>
        <Posts list={prefixArticles(edges)} />
      </Container>
    </>
  )
}

function prefixArticles(edges) {
  edges.forEach(prefixArticle)
  return edges
}
function prefixArticle({ node }) {
  if (!node.slug.includes("blog")) {
    node.slug = `blog/${node.slug}`
  }
}
