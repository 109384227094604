import React from "react"

import { makeStyles } from "@material-ui/core/styles"

import { Image } from "@src/components"

const useStyles = makeStyles(theme => ({
  figure: { margin: theme.spacing(0, 0, 4) },
}))

export default function Figure(props) {
  const { image } = props
  const classes = useStyles()

  return (
    <figure className={classes.figure}>
      <Image {...image} />
    </figure>
  )
}
