import React from "react"

import { Box } from "@material-ui/core"
import { CircularProgress } from "@src/components"

export default function FallbackComments() {
  return (
    <Box textAlign="center">
      <CircularProgress />
    </Box>
  )
}
