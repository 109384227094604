import React from "react"
import PropTypes from "prop-types"

import ReactMarkdown from "react-markdown"
import { CodeBlock, HeadingRenderer, MarkdownImage } from "./components"

export default function Content(props) {
  const { source, extraImages } = props

  return (
    <ReactMarkdown
      source={source}
      renderers={{
        code: CodeBlock,
        image: MarkdownImage(extraImages),
        heading: HeadingRenderer,
      }}
    />
  )
}

Content.propTypes = {
  content: PropTypes.string.isRequired,
}
