import React from "react"

import Highlight, { defaultProps } from "prism-react-renderer"
import theme from "prism-react-renderer/themes/vsDark"

import { Line, Pre, LineNo, LineContent } from "./components"
import { highlightLine, useShowLineNumber } from "./utils"

export default function CodeBlock(props) {
  const { value } = props
  const { content, showLineNumber } = useShowLineNumber(value)

  return (
    <Highlight {...defaultProps} theme={theme} code={content} language="jsx">
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <Pre className={className} style={style}>
          {tokens.map((line, i) => {
            const lineProps = getLineProps({ line, key: i })
            const shouldExclude = highlightLine(line, lineProps)

            return !shouldExclude ? (
              <Line key={i} {...lineProps}>
                {showLineNumber && <LineNo>{i + 1}</LineNo>}
                <LineContent>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </LineContent>
              </Line>
            ) : null
          })}
        </Pre>
      )}
    </Highlight>
  )
}
