import React from "react"
import PropTypes from "prop-types"

import { withStyles } from "@material-ui/core/styles"
import { Typography, Box } from "@material-ui/core"

const StyledBox = withStyles(theme => ({
  root: { margin: theme.spacing(0, 0, theme.custom.spacing.big) },
}))(Box)

export default function Header({ title, published_at, authorName }) {
  return (
    <StyledBox component="header">
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        {title}
      </Typography>
      <Typography align="center" gutterBottom>
        {published_at}&nbsp;- by&nbsp;<strong>{authorName}</strong>
      </Typography>
    </StyledBox>
  )
}

Header.propTypes = {
  authorName: PropTypes.string.isRequired,
  published_at: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
