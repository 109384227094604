import React from "react"

import { Container, SEO, Divider } from "@src/components"
import { BottomNav, Figure, Header, Comments, Content } from "./components"

export default function Post({ data, pageContext }) {
  const {
    strapiId,
    description,
    content,
    published_at,
    title,
    updated_at,
  } = data.strapiArticle
  const { banner, extraImages, previous, next } = pageContext

  return (
    <>
      <SEO
        title={title}
        description={description}
        img={data.strapiArticle.banner}
        article={{ published_at, updated_at }}
      />
      <article>
        <Container component="section">
          <Header
            authorName={data.strapiAuthor.name}
            published_at={published_at}
            title={title}
          />
          <Figure image={banner} />
          <Content source={content} extraImages={extraImages} />
          <Divider />
          <Comments articleId={strapiId} />
          <Divider />
          <BottomNav next={next} previous={previous} />
        </Container>
      </article>
    </>
  )
}
