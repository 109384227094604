import React from "react"

import { makeStyles } from "@material-ui/core/styles"

import GatsbyImage from "gatsby-image"
import { Grid, Typography } from "@material-ui/core"
import { Link } from "@src/components"

import imageFactory from "@services/imageFactory"

const usePostStyles = makeStyles(theme => {
  const postSpacing = theme.spacing(6)

  return {
    linkContainer: {
      "& > section": {
        marginTop: postSpacing,
        marginBottom: postSpacing,
      },
      "&:last-child > section": {
        marginBottom: 0,
      },
    },
    postImg: { width: "100%" },
    postImgContainer: { display: "flex", alignItems: "center", width: "100%" },
    header: { marginBottom: theme.spacing(2) },
    description: { color: theme.palette.text.primary },
  }
})

export default function Post({ node }) {
  const classes = usePostStyles()
  const title = node.title
  const banner = imageFactory.createFromDB(node.banner)

  return (
    <Link key={node.slug} className={classes.linkContainer} to={node.slug}>
      <Grid container component="section" spacing={4}>
        <Grid
          item
          component="aside"
          xl={12}
          sm={4}
          className={classes.postImgContainer}
        >
          <GatsbyImage className={classes.postImg} {...banner} />
        </Grid>
        <Grid item component="article" xs={12} sm={8}>
          <header className={classes.header}>
            <Typography variant="h6" component="h3">
              {title}
            </Typography>
            <Typography variant="caption" component="small">
              {node.published_at}
            </Typography>
          </header>
          <section>
            <Typography
              className={classes.description}
              dangerouslySetInnerHTML={{
                __html: node.description,
              }}
            />
          </section>
        </Grid>
      </Grid>
    </Link>
  )
}
