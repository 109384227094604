import React from "react"
import PropTypes from "prop-types"

import { Grid } from "@material-ui/core"
import { Avatar, Intro } from "./components"

export default function Author(props) {
  const { avatar, bio } = props

  return (
    <Grid container spacing={4}>
      <Avatar photo={avatar} />
      <Intro content={bio} />
    </Grid>
  )
}

Author.propTypes = {
  avatar: PropTypes.string.isRequired,
  bio: PropTypes.string.isRequired,
}
