import React from "react"
import PropTypes from "prop-types"

import { FallbackComments, LoadComments } from "./components"

const ShowComments = React.lazy(() => import("./components/ShowComments"))

export default function Comments(props) {
  const { articleId } = props
  const [display, setDisplay] = React.useState(false)

  const loadComments = React.useCallback(() => {
    setDisplay(true)
  }, [])

  return display ? (
    <React.Suspense fallback={<FallbackComments />}>
      <ShowComments articleId={articleId} />
    </React.Suspense>
  ) : (
    <LoadComments onClick={loadComments} />
  )
}

Comments.propTypes = {
  articleId: PropTypes.number.isRequired,
}
