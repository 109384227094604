import React from "react"

import { useLocation } from "@reach/router"
import queryString from "query-string"

import { withStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"

import { Container, SEO, CircularProgress } from "@src/components"
import {
  putSubscribe,
  isSuccessResponse,
  parseResponse,
  cancelRequest,
} from "@components/Layout/components/Footer/components/Subscribe/utils"
import { validateQueries } from "@views/shared/utils"

const META_TAGS = [
  { name: "robots", content: "noindex,nofollow" },
  { name: "googlebot", content: "noindex,nofollow" },
]
const QUERIES = ["email"]

const StyledContainer = withStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
})(Container)

export default function UnSubscribe() {
  const [content, setContent] = React.useState("Subscribe")
  const { search } = useLocation()

  React.useEffect(
    function unsubsribeOnMounted() {
      let promise
      const queries = queryString.parse(search)
      const queriesAreValid = validateQueries(QUERIES, queries)

      if (!queriesAreValid) {
        setContent("Email is missing.")
      } else {
        setContent("fetching")

        promise = putSubscribe(queries.email)
        promise
          .then(isSuccessResponse)
          .then(parseResponse)
          .then(setContent)
          .catch(error => {
            console.error(error)
            setContent("Something went wrong.")
          })
      }

      return function onUnMounted() {
        cancelRequest(promise, "Component un mounted.")
      }
    },
    [search]
  )

  return (
    <>
      <SEO title="Verification" meta={META_TAGS} />
      <StyledContainer>
        <Typography variant="body1">
          {content === "fetching" ? <CircularProgress /> : content}
        </Typography>
      </StyledContainer>
    </>
  )
}
