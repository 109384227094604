import React from "react"
import PropTypes from "prop-types"

import { makeStyles } from "@material-ui/core/styles"

import { Box } from "@material-ui/core"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { Link } from "@src/components"

import { prefixSlug } from "./helpers"

const useStyles = makeStyles({
  ul: {
    listStyle: "none",
    "& > li > a": { display: "flex", alignItems: "center" },
  },
})

export default function BottomNav(props) {
  const { next, previous } = props
  const classes = useStyles()

  return (
    <nav>
      <Box
        className={classes.ul}
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        padding="0"
        component="ul"
      >
        <li>
          {previous && (
            <Link to={prefixSlug(previous.slug)} rel="prev">
              <ArrowBackIosIcon />
              &nbsp;{previous.title}
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link to={prefixSlug(next.slug)} rel="next">
              {next.title}&nbsp;
              <ArrowForwardIosIcon />
            </Link>
          )}
        </li>
      </Box>
    </nav>
  )
}

const linkPropTypes = PropTypes.shape({
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
})

BottomNav.propTypes = {
  next: linkPropTypes,
  previous: linkPropTypes,
}
