const OTHER_FORMATS = ["webp"]
const CACHE = new Map()

module.exports = {
  createFromDB(banner, maxWidth = 800) {
    const { formats, height, url, width } = banner
    const image = {
      alt: banner.alternativeText,
      caption: banner.caption,
      fluid: {
        aspectRatio: width / height,
        sizes: `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`,
      },
    }

    if (url && CACHE.has(url)) return CACHE.get(url)

    for (const { format, url, width } of formats) {
      if (format === "base64") {
        image.fluid.base64 = url
        continue
      }
      const isOtherFormat = OTHER_FORMATS.includes(format)
      const srcKey = "src".concat(isOtherFormat ? capitalize(format) : "")
      const srcSetKey = "srcSet".concat(isOtherFormat ? capitalize(format) : "")

      /*
       * Set current url as src if:
       * - src is undefined,
       * - src has lower width than current url
       */
      if (!(srcKey in image.fluid)) {
        image.fluid[srcKey] = url
      } else if (image.fluid[srcKey] <= width) {
        image.fluid[srcKey] = url
      }

      if (!(srcSetKey in image.fluid)) {
        image.fluid[srcSetKey] = []
      }

      image.fluid[srcSetKey].push(`${url} ${Math.round(width)}w`)
    }

    // Register the original image
    image.fluid.src = url

    const keys = Object.keys(image.fluid)
    for (const key of keys) {
      let value = image.fluid[key]
      const isSrcSet = typeof value !== "undefined" && Array.isArray(value)
      if (isSrcSet) {
        image.fluid[key] = value.join(`,\n`)
      }
    }

    CACHE.set(url, image)
    return image
  },
}

function capitalize(str) {
  return str[0].toUpperCase() + str.substring(1)
}
