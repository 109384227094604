import React from "react"

import { makeStyles } from "@material-ui/core/styles"

import { Grid } from "@material-ui/core"
import GatsbyImage from "gatsby-image"

const useStyles = makeStyles(theme => {
  return {
    container: {
      [theme.breakpoints.up("sm")]: { display: "flex", alignItems: "center" },
    },
    avatar: {
      borderRadius: "50%",
      margin: "auto",
      maxWidth: "50%",
      [theme.breakpoints.up("sm")]: {
        maxWidth: "80%",
        width: "100%",
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: "100%",
      },
    },
  }
})

export default function Avatar({ photo }) {
  const classes = useStyles()

  return (
    <Grid className={classes.container} item xs={12} sm={4}>
      <GatsbyImage className={classes.avatar} {...photo} />
    </Grid>
  )
}
