import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

const useStyles = makeStyles({
  root: {
    display: "table-cell",
    textAlign: "right",
    paddingRight: "1em",
    userSelect: "none",
    opacity: 0.5,
  },
})

export default function LineNo(props) {
  const { children, className } = props
  const classes = useStyles()

  return <span className={clsx(classes.root, className)}>{children}</span>
}
